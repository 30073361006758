<template>
    <a-card>
        <a-page-header
            :title='"视频案例管理"'
        />
        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="视频案例ID">
                        <a-input
                            v-model="form.caseNo"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="视频标题">
                        <a-input
                            v-model="form.videoTitle"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="8">
                    <a-form-model-item label="视频品牌">
                        <a-select
                            mode="multiple"
                            v-model="form.principalIdList"
                            placeholder="请选择品牌"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                            @change="onChangePrincipal"
                        >
                            <a-select-option
                                v-for="item in principalList"
                                :key="item.id"
                            >
                                {{ item.principal_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="视频车系">
                        <a-select
                            mode="multiple"
                            v-model="form.carSeriesIdList"
                            placeholder="请选择车系"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in seriesList"
                                :key="item.id"
                            >
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="flex-end">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :row-key="(record, index) => index"
            :pagination='false'
            :scroll="{x: 2000}"
            class="mt-40"
        >
            <div slot="action" slot-scope="text, record">
                <base-button
                    :type="'link'"
                    :title="'修改'"
                    @onClickBtn="jumpDetailPage(record.id)"
                ></base-button>
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />

        <div class="mt-40">
            <div>PS：优秀的视频案例筛选规则</div>
            <div>1、统计范围</div>
            <div>各广告平台所有托管中的平台账套</div>
            <div>2、统计周期</div>
            <div>（1）统计范围，由视频创建（上传到广告平台）的第1天到100天</div>
            <div>（2）统计周期为视频连续7天的投放效果</div>
            <div>例如：视频上线广告平台时间是2022年1月1日，统计范围就是2022年1月1日~2022年4月10日，统计数据分别为，2022年1月1日~2022年1月7日、2022年1月2日~2022年1月8日、2022年1月3日~2022年1月9日...2022年4月4日~2022年4月10日</div>
            <div>3、记录符合以下条件的视频数据为案例，相同视频（广告平台视频ID相同）只要符合可以有多个案例</div>
            <div>（1）消费≥300元<span class="red">且</span>平均转化成本≥5元<span class="red">且</span>曝光转化率（转化数÷播放数）≥0.10%</div>
            <div>（2）统计周期内，有消费天数≥4天，例如：视频在1月1~7日的记录符合上述数据，但有消费的天数只有5、6、7号3天，不满足消费天数条件，所以不能作为案例记录</div>
        </div>

        <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handlePreviewCancel"
            width="480px"
            :zIndex="10001"
        >
            <video
                :src="previewSrc"
                controls
                style="height: 480px; width: 100%"
            >
                你的浏览器不支持video
            </video>
        </a-modal>
    </a-card>
</template>

<script>
import utils from "@/utils/FormatUtil.js";

export default {
    data() {
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {
                principalIdList: [],
                carSeriesIdList: [],
            },
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '案例编码',
                    dataIndex: 'caseNo',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '视频创建日期',
                    dataIndex: 'videoCreateTime',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '视频标题',
                    dataIndex: 'videoTitle',
                    customRender:(text, row) => {
                        return <a on-click={() => this.handlePreviewOpen(row.hjkAdVideoId)}>{ row.videoTitle || '-' }</a>
                    }
                },
                {
                    align: 'center',
                    title: '统计开始日期',
                    dataIndex: 'statisticsStartDate',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '统计结束日期',
                    dataIndex: 'statisticsEndDate',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '统计天数',
                    dataIndex: 'statisticsPeriod',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '消费（元）',
                    dataIndex: 'cost',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '播放数',
                    dataIndex: 'totalPlay',
                    customRender: (text, row) => {
                        return (row.totalPlay ?? '') !== '' ? utils.humanReadable(row.totalPlay, 2) : '-'
                    }
                },
                {
                    align: 'center',
                    title: '转化数',
                    dataIndex: 'convert',
                    customRender: (text, row) => {
                        return (row.convert ?? '') !== '' ? utils.humanReadable(row.convert, 2) : '-'
                    }
                },
                {
                    align: 'center',
                    title: '转化平均成本（元）',
                    dataIndex: 'convertAverageCost',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '曝光点击率',
                    dataIndex: 'exposureClickRate',
                    customRender: (text, row) => {
                        return (row.exposureClickRate ?? '') !== '' ? `${row.exposureClickRate}%` : '-'
                    }
                },
                {
                    align: 'center',
                    title: '点击转化率',
                    dataIndex: 'clickConvertRate',
                    customRender: (text, row) => {
                        return (row.clickConvertRate ?? '') !== '' ? `${row.clickConvertRate}%` : '-'
                    }
                },
                {
                    align: 'center',
                    title: '曝光转化率',
                    dataIndex: 'exposureConvertRate',
                    customRender: (text, row) => {
                        return (row.exposureConvertRate ?? '') !== '' ? `${row.exposureConvertRate}%` : '-'
                    }
                },
                {
                    align: 'center',
                    title: '视频品牌',
                    dataIndex: 'principalName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '视频车系',
                    dataIndex: 'carSeriesName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '导拍模板',
                    customRender: (text, row) => {
                        return <div style="text-align: left;">
                                <div>{ row.guideTemplateName || '-' }</div>
                                <div class="txt">ID：{row.guideTemplateId || '-'}</div>
                            </div>
                    }
                },
                {
                    align: 'center',
                    title: '广告主',
                    dataIndex: 'advertiserName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告平台',
                    customRender: (text, row) => {
                        return row.adPlatformType == 1 ? '巨量广告' : row.adPlatformType == 2 ? '磁力广告' : row.adPlatformType == 3 ? '百度信息流' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '操作',
                    scopedSlots: { customRender: 'action' }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            principalList: [],
            seriesList: [],
            previewVisible: false,
            previewSrc: '',
        }
    },
    created() {
        this.getPrincipalList()
        this.getPrincipalSeriesList()
        this.getDataList()
    },
    methods: {
        handlePreviewOpen(id) {
            this.$api.core.materialManage.getUrlByHjkAdVideoId(id).then((res) => {
                if(res.code == 200) {
                    window.open(res.data.playUrl, '_blank', 'noreferrer=yes')
                } else {
                    this.$message.error('获取视频地址失败')
                }
            })
        },
        handlePreviewCancel() {
            this.previewVisible = false
            this.previewSrc = ''
        },
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res}`)
                }
            })
        },
        onChangePrincipal(id) {
            this.getPrincipalSeriesList(id)
        },
        getPrincipalSeriesList(id) {
            let params = {
                principalIds: id,
                status: [1, 4]
            }
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    this.seriesList = res.data
                } else {
                    this.seriesList = []
                    console.error(`获取车系失败，${res}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {}
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList() {
            this.loading = true
            const _form = this.form
            let params = Object.assign(_form, {
                page: this.pagination.current,
                size: this.pagination.pageSize
            })
            this.$api.core.pphtManage.getVideoCaseStatisticsPage(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
        jumpDetailPage(id) {
            this.$router.push({
                path: `/videoCaseManages/detail`,
                query: {
                    id,
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.mt-40 {
    margin-top: 40px;
}
</style>